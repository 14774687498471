import "./Product.scss";
import CardProduct from "../../Component/Card/CardProduct.jsx";
import dataProduct from "./index.json";
import { ReactComponent as Divider } from "../../Assets/svg/divider.svg";
import {
  studioImages,
  fbrImages,
  sbrImages,
} from "../../Assets/Product/index.js";
const imageMap = {
  studioImages,
  fbrImages,
  sbrImages,
};
const Product = () => {
  return (
    <section className="section-produk" id="rumah">
      <div className="container" id="unit-apartemen">
        <Divider fill="#735b0c" />
        <h2 className="title">APARTMENT UNIT</h2>
        <div className="container-card">
          {dataProduct.cardsResidentials.map((data, index) => (
            <CardProduct
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              details={data.details}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Product;
