import "./Location.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconOffice } from "../../Assets/svg/office.svg";
import map from "../../Assets/map (2).webp";
const Location = () => {
  const pointData = {
    poin3: [
      "2 menit ke Stasiun LRT Ciracas",
      "3 menit ke Bandara Halim Perdanakusuma",
      "5 menit ke Taman Mini Indonesia Indah (TMII)",
      "10 menit ke Pintu Tol Jatiwarna",
      "15 menit ke Terminal Kampung Rambutan",
      "20 menit ke kawasan SCBD",
      "20 menit ke kawasan bisnis TB. Simatupang",
      "25 menit ke Bandara Halim Perdanakusuma",
    ],
  };
  return (
    <section id="location" className="section-surrounding">
      <div className="container-surrounding">
        <h2 className="title">Location</h2>
        <div className="container-image">
          <img
            src={map}
            alt="Area Sekitar Sakura Garden City"
            className="image"
          />
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconOffice className="icon-fasilitas" />
              <span>Surrounding Location</span>
            </div>
            {pointData.poin3.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-loc"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
