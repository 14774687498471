import studio from "./Studio/Studio (1).jpg";
import studio2 from "./Studio/Studio (2).jpg";
import studio3 from "./Studio/Studio (3).jpg";
import fbr from "./1BR/1BR (1).jpg";
import fbr2 from "./1BR/1BR (2).jpg";
import fbr3 from "./1BR/1BR (3).jpg";
import fbr4 from "./1BR/1BR (4).jpg";
import sbr from "./2BR/2BR (1).jpg";
import sbr2 from "./2BR/2BR (2).jpg";
import sbr3 from "./2BR/2BR (3).jpg";
import sbr4 from "./2BR/2BR (4).jpg";
import sbr5 from "./2BR/2BR (5).jpg";

const studioImages = [studio3, studio2, studio];
const fbrImages = [fbr4, fbr2, fbr3, fbr];
const sbrImages = [sbr4, sbr2, sbr3, sbr5, sbr];

export { studioImages, fbrImages, sbrImages };
