import React from "react";
import "./Home.scss";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Product from "../../Section/Product/Product";
import Facilities from "../../Section/Facilities/Facilities";
import Location from "../../Section/Location/Location";
import ScrollToAnchor from "./ScrollToAnchor";
const Home = () => {
  return (
    <div className="home">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Product />
      <Facilities />
      <Location />
    </div>
  );
};

export default Home;
