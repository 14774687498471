import "./Hero.scss";

const Hero = () => {
  return (
    <section id="hero" className="section-hero">
      <div className="container-hero">
        <div className="hero-description">
          <h6 className="hero-title">WELCOME TO</h6>
          <h4 className="hero-text">SAKURA GARDEN CITY</h4>
        </div>
      </div>
    </section>
  );
};

export default Hero;
