import "./About.scss";
import aboutImg from "../../Assets/about.jpg";
import { ReactComponent as Checkin } from "../../Assets/svg/checkin.svg";

const About = () => {
  const pointData = [
    "Lokasi Strategis di Jakarta",
    "Sertifikat Strata Title DKI Jakarta",
    "One Stop Living, Fasilitas Lengkap",
    "Pengembang Terpercaya",
    "60% lahan ruang terbuka hijau",
  ];
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6285161152939&text=Halo+Wildan,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Apartemen%20Sakura%20Garden%20City)%20https://apartment-sakuragardencity.com/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section className="section-about" id="about">
      <div className="container-about">
        <div className="caption">Sakura Garden City</div>
        <h2 className="title">Live, work, play, connected.</h2>
        <p className="description">
          Sakura Garden City, proyek kolaborasi antara pengembang Indonesia dan
          Jepang, menghadirkan hunian eksklusif dengan standar kualitas Jepang
          yang terjamin. Dengan konsep TOD yang inovatif, apartemen ini
          dirancang untuk memenuhi kebutuhan gaya hidup modern Anda. Rasakan
          kenyamanan dan kemewahan tinggal di kawasan superblok terbesar di
          Jakarta Timur."
        </p>
        <div className="container-hook">
          <div className="container-image">
            <img src={aboutImg} alt="about" />
          </div>
          <div className="container-checkin">
            {pointData.map((point, index) => (
              <div className="checkin" key={index}>
                <Checkin width={19} height={19} fill="#ffe08d" />
                <span>{point}</span>
              </div>
            ))}
            <button onClick={waOpen} className="btn-navbar">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
